export class OperatorsModel {
  constructor(
    public username: string = null,
    public password: string = null,
    public code: number = null,
    public id?: number
  ) {
  }
}

export interface Operators {
  operators: OperatorsModel[];
}
