import {BaseApi} from '../../shared/core/base-api';
import {Http} from '@angular/http';
import {SearchModel} from './model/search.model';
import {Injectable} from '@angular/core';
import {FindCatalogueInstituteModel} from './model/find-catalogue-institute.model';
import {FindAllReggedModel} from './model/find-all-regged.model';


@Injectable()
export class ZayavService extends BaseApi {
    constructor(public http: Http) {
      super(http);

    }

    searchAbit(search: SearchModel) {
      return this.post(`konkurs/zayav/pvo/api/students/find`, search);
    }

    selectScoreType(id){
      return this.get(`konkurs/zayav/pvo/api/students/catalogue/foreignLangCertificateType/character?id=` + id);
    }

    findStudentExtended(ikt: number) {
      return this.get(`konkurs/zayav/pvo/api/students/findByIkt?ikt=${ikt}`);
    }


    // сервисы
    // тип сертификата
    certifaceType() {
      return this.get(`konkurs/zayav/pvo/api/students/catalogue/foreignLangCertificateType`);
    }

    isClosedDB() {
      return this.get(`konkurs/zayav/pvo/api/institute/isClosed`)
    }

    // форма обучения
    catalogueStudyForm() {
      return this.get(`konkurs/zayav/pvo/api/students/catalogue/studyForm`);
    }

    olimpTypeByIkt(ikt: number) {
      return this.get(`konkurs/zayav/pvo/api/students/catalogue/findOlimpTypeByIkt?ikt=${ikt}`);
    }


    // Справочник - группы образовательных программ.
    getEduProgram(ikt: number, studyFormId: number ) {
      return this.get(`konkurs/zayav/pvo/api/students/catalogue/findEduProgram?ikt=${ikt}&studyFormId=${studyFormId}`);
    }

    findCatalogueInstitute(data: FindCatalogueInstituteModel) {
      return this.post(`konkurs/zayav/pvo/api/students/catalogue/findInstitute`, data);
    }

    olimpSubjectByIktAndOlimpType(ikt: number, olimpTypeId: number) {
      return this.get(`konkurs/zayav/pvo/api/students/catalogue/findOlimpSubject?ikt=${ikt}&olimpTypeId=${olimpTypeId}`);
    }

    abiturientsAdd(data) {
      return this.post(`konkurs/zayav/pvo/api/abiturients/add`, data);
    }

    // Получение полного списка абитуриентов

    findAllabiturients() {
      return this.get(`konkurs/zayav/pvo/api/abiturients/findAllActive`);
    }

    registerAbiturients(id: number) {
      return this.post(`konkurs/zayav/pvo/api/abiturients/register?id=${id}`);
    }

    revokeAbiturients(id: number) {
      return this.post(`konkurs/zayav/pvo/api/abiturients/revoke?id=${id}`);
    }


    findAllReggedAbiturients(data: FindAllReggedModel) {
      return this.post(`konkurs/zayav/pvo/api/abiturients/findAllRegistered`, data);
    }


    findActiveAbiturient(ikt: number) {
      return this.get(`konkurs/zayav/pvo/api/abiturients/findActive?ikt=${ikt}`);
    }

    revokeAbit(id: number) {
      return this.delete(`konkurs/zayav/pvo/api/abiturients/delete?id=${id}`);
    }

    findAllRevoked() {
      return this.get(`konkurs/zayav/pvo/api/abiturients/findAllRevoked`);
    }

    closeDataBase() {
      return this.put(`konkurs/zayav/pvo/api/institute/close`);
    }

    instituteStat(){
      return this.get(`konkurs/zayav/pvo/api/institute/instituteStatistics`);
    }

    grantCertificate(){
      return this.get(`konkurs/zayav/pvo/api/institute/grantCertificate`);
    }


}
