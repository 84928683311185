export class ErrorMessage {
  constructor(
    public errorText: string = '',
    public status: string = 'success'
  ) { }

  message(text: string, status: string = 'danger') {
    this.errorText = text;
    this.status = status;
    setTimeout(() =>  {
      this.errorText = '';
    }, 3000);
  }


}
