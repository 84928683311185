import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OperatorsModel} from '../../shared/model/operators.model';
import {OperatorsService} from '../../shared/services/operators.service';
import {CommonModel} from '../../../shared/model/common.model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {EditOperator} from '../../actions/operator';
import {AppState} from '../../reducers/app.state';
import {Store} from '@ngrx/store';
import {ErrorMessage} from '../../../shared/model/error.model';
import {TranslateService} from '@ngx-translate/core';



@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

  modalRef: BsModalRef;
  form: FormGroup;
  submitted: boolean = false;
  message: ErrorMessage = new ErrorMessage();

  @ViewChild("templateEdit") tpl: TemplateRef<any>;


  @Output()
  text: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public operatorsService: OperatorsService,
    public commonModel: CommonModel,
    private modalService: BsModalService,
    public store: Store<AppState>,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.form = this.formValidtation(new OperatorsModel());
  }

  show(data) {
    this.form = this.formValidtation(data);
    this.openModal(this.tpl);

  }

  formValidtation(data: any) {
    return new FormGroup({
      id: new FormControl(data.id, [Validators.required]),
      username: new FormControl(data.username, [Validators.required]),
      password: new FormControl(data.password, [Validators.required]),
      code: new FormControl(data.code, [Validators.required])
    });
  }

  edit() {
    this.submitted = true;
    if (!this.form.invalid) {
      this.operatorsService.updateOperators(this.form.value)
        .subscribe(data => {
          this.store.dispatch(new EditOperator(data.data));
          this.text.emit(this.translate.currentLang === 'ru' ? 'Оператор успешно изменен!' : 'Оператор сәтті өзгертілді');
          this.modalRef.hide();
        }, error => {
          this.message.message(this.translate.currentLang === 'ru' ? error.errorMessageRu : error.errorMessageKz);
        });
    }


  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  get errors() {
    return this.form.controls;
  }

}
