import { Component, OnInit } from '@angular/core';
import {RevokeModel} from '../shared/model/revoke.model';
import {ZayavService} from '../shared/zayav.service';

@Component({
  selector: 'app-revoked-lists',
  templateUrl: './revoked-lists.component.html',
  styleUrls: ['./revoked-lists.component.css']
})
export class RevokedListsComponent implements OnInit {

  revokeLists: RevokeModel[];


  constructor(
    public zayavService: ZayavService
  ) { }

  ngOnInit() {    this.zayavService.findAllRevoked()
      .subscribe(data => {
        this.revokeLists = data.datas;
      })
  }

}
