import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'pagination'
})
@Injectable()
export class PaginationPipe implements PipeTransform {
  transform(items: any[], count: number, page: number) {
    if (!items) return items;
    const start = count * (page - 1);
    return items.slice(start, start + count);
  }
}
