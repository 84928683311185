import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {OperatorsService} from '../shared/services/operators.service';
import {EditComponent} from './edit/edit.component';
import {AddComponent} from './add/add.component';
import {ErrorModel} from '../../zayav/shared/model/error.model';
import {CommonModel} from '../../shared/model/common.model';
import {Store} from '@ngrx/store';
import {AppState} from '../reducers/app.state';
import {Observable} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {OperatorsModel} from '../shared/model/operators.model';
import {RemoveOperator} from '../actions/operator';
import {ErrorMessage} from '../../shared/model/error.model';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.css']
})
export class OperatorsComponent implements OnInit {

  modalRef: BsModalRef;
  message: ErrorMessage = new ErrorMessage();
  operatorState: Observable<any>;
  search: string;
  itemsPerPage: number = 10;
  totalCount: number;

  @ViewChild(EditComponent)
  editComponent: EditComponent;

  @ViewChild(AddComponent)
  addComponent: AddComponent;

  error: ErrorModel = new ErrorModel();
  activeId: number;

  contentArray = new Array(10).fill('');
  returnedArray: string[];

  constructor(
    public store: Store<AppState>,
    public operatorsService: OperatorsService,
    public commonModel: CommonModel,
    private modalService: BsModalService,
    public translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.operatorState = this.store.select('operatorPage');
  }

  edit(data) {
    this.editComponent.show(data);
  }

  add() {
    this.addComponent.show();
  }

  delete() {
    this.operatorsService.removeOperators(this.activeId)
      .subscribe(data => {
        this.store.dispatch(new RemoveOperator(new OperatorsModel(null, null, null, this.activeId)));
        this.message.message('Оператор удален успешно', 'success');
      }, error => {
        this.message.message(this.translate.currentLang === 'ru' ? error.errorMessageRu : error.errorMessageKz);
      });
    this.modalRef.hide();
  }

  showMessage(text: string, status: string = 'success') {
    this.message.message(text, status);
  }

  currentUser(id: number) {
    this.activeId = id;
  }

  openModal(template: TemplateRef<any>, id: number) {
    this.activeId = id;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  showPass(event) {
    if (event.target.localName === 'i') {
      event.target.classList.add('show_pass');
    }
  }

  decline() {
    this.modalRef.hide();
  }




}



