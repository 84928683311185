import {ZayavService} from '../zayav.service';
import {Injectable} from '@angular/core';
import {FindStudentModel} from './find-student.model';
import {Router} from '@angular/router';
import {SpravModel} from './sprav.model';
import {CommonModel} from '../../../shared/model/common.model';
import {IStudentsSearch} from './interfaces';
import {FormGroup} from '@angular/forms';
import {map} from 'rxjs/operators';
import {ErrorMessage} from '../../../shared/model/error.model';



@Injectable()
export class RepositoryModel {

  public abitLists: IStudentsSearch[];
  public totalCount: number = 0;

  public abiturientsLists: any[] = [];
  public findStudentData: FindStudentModel;
  public certificateType: SpravModel;

  public studyLists: SpravModel[];
  public active: boolean = false;
  public activeAbitLists: any[] = [];

  // Институты
  public instituteOne;
  public instituteTwo = [];
  public instituteThree = [];
  public instituteFour = [];

  // Олимпиада

  constructor(
    private dataSource: ZayavService,
    private router: Router,
    private common: CommonModel
  ) {
    this.dataSource.certifaceType().subscribe(data => {
      this.certificateType = data.data;
      console.log(this.certificateType);
    });


    this.styduForm();
    this.findAllAbiturients();
  }


  searchAbit(form: FormGroup, message: ErrorMessage) {
    this.dataSource.searchAbit(form.value)
      .subscribe(data => {
        form.reset();
        this.abitLists = data.data;
        this.totalCount = this.abitLists.length;
        if (!data.data.length) {
          message.message('Не найдено', 'danger');
        }
      }, error => {
        message.message(error.errorMessageRu, 'danger');
      })
  }


  findAllAbiturients() {
    this.dataSource.findAllabiturients()
      .subscribe(data => {
        this.activeAbitLists = data.datas;
      })
  }

  findStudent(ikt: number) {
    this.dataSource.findStudentExtended(ikt)
      .subscribe(data => {
        this.findStudentData = data.data;
        this.router.navigate(['./add-abit']);
      })
  }

  update(data) {
    this.findAllAbiturients();
    const find = this.activeAbitLists.find(item => item.ikt === data.ikt);
    for (let key in find) {
      find[key] = data[key];
    }
  }

  findCatalogueInstitute(data: any) {
    return this.dataSource.findCatalogueInstitute(data)
  }

  styduForm() {
    this.dataSource.catalogueStudyForm()
      .subscribe(data => {
        this.studyLists = data.data;
      })
  }
}
