import {NgModule} from '@angular/core';
import {OperatorsComponent} from './operators/operators.component';
import {EditComponent} from './operators/edit/edit.component';
import {AddComponent} from './operators/add/add.component';
import {OperatorsService} from './shared/services/operators.service';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {operatorReducer} from './reducers/operator.reducer';
import {ModalModule} from 'ngx-bootstrap/modal';
import {AdminComponent} from './admin.component';
import {PipesModule} from '../shared/pipe/pipes.module';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot({operatorPage: operatorReducer}),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    TranslateModule,
    PipesModule
  ],
  declarations: [
    OperatorsComponent,
    EditComponent,
    AddComponent,
    AdminComponent
    ],
  providers: [OperatorsService]
})


export class AdminModule {

}
