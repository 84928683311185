import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OperatorsComponent} from './admin/operators/operators.component';
import {AuthGuard} from './shared/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: 'auth/univercity', pathMatch: 'full' },
  { path: 'operator', canActivate: [AuthGuard], component: OperatorsComponent },
  { path: 'admin', canActivate: [AuthGuard], component: OperatorsComponent }
];



@NgModule({
  imports: [RouterModule.forRoot(routes,  { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
