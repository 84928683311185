import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../shared/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  currentLang: string = 'Рус';
  username: string = '';
  show: boolean = true;
  caret: boolean = false;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public translate: TranslateService,
    public authService: AuthService
  ) {
    if (localStorage.getItem('lang')  === 'kz') {
      this.translate.use('kz');
      this.currentLang = 'Қаз';
    } else {
      this.translate.use('ru');
    }

    router.events.subscribe(val => {
      this.show = this.authService.authenticated();
      if (sessionStorage.getItem('user')) {
        this.username = JSON.parse(sessionStorage.getItem('user')).username;
      }
    })
  }








  exit() {
    this.router.navigate(['./auth/univercity']);
    sessionStorage.clear();
    this.authService.logout();
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('lang', language);
  }




}
