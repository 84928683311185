import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { ZayavService } from '../shared/zayav.service';
import { TranslateService } from '@ngx-translate/core';
import { PageChangedEvent } from 'ngx-bootstrap';
import { StatService } from './stat.service';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.css']
})
export class StatComponent implements OnInit {

  data: any[] = [];
  downloadButton = false;

  constructor(private service: ZayavService, public translate: TranslateService, private statService:StatService) {
  }

  ngOnInit() {


    this.service.instituteStat().subscribe({
      next: (res) => {
        this.data = res;
      }
    });


  }


  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.data = this.data.slice(startItem, endItem);
  }

  exportexcel(): void {
    this.downloadButton = true;

    this.statService.downloadZayav().subscribe({
      next: (res) => {
        this.downloadButton = false;
        const date = new Date();

        let currentDay = String(date.getDate()).padStart(2, '0');

        let currentMonth = String(date.getMonth() + 1).padStart(2, "0");

        let currentYear = date.getFullYear();

        let currentDate = `${currentDay}-${currentMonth}-${currentYear}`;
        saveAs(res, "Статистика по приему заявлений на Конкурс_ " + currentDate + ".xlsx");
      }
    });

  }

}
