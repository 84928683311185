import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ZayavComponent} from './zayav.component';
import {ZayavRoutingModule} from './zayav-routing.module';
import {SearchAbitComponent} from './search-abit/search-abit.component';
import {AddAbitComponent} from './add-abit/add-abit.component';
import {ZayavService} from './shared/zayav.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {RepositoryModel} from './shared/model/repository.model';
import {CommonModel} from '../shared/model/common.model';
import { ActiveAbitComponent } from './active-abit/active-abit.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {ZayavListsComponent} from './zayav-lists/zayav-lists.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PipesModule} from '../shared/pipe/pipes.module';
import {TranslateModule} from '@ngx-translate/core';
import { RevokedListsComponent } from './revoked-lists/revoked-lists.component';
import { StatComponent } from './stat/stat.component';
import { GrantListsComponent } from './grant-lists/grant-lists.component';




@NgModule({
  imports: [
    CommonModule,
    ZayavRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    PipesModule,
    TranslateModule
  ],
  declarations: [
    ZayavComponent,
    SearchAbitComponent,
    AddAbitComponent,
    ActiveAbitComponent,
    SidebarComponent,
    ZayavListsComponent,
    RevokedListsComponent,
    StatComponent,
    GrantListsComponent
  ],
  providers: [
    ZayavService,
    RepositoryModel,
    CommonModel
  ]
})



export class ZayavModule {

}
