export class ImmutableDataModel {
  constructor(
    public firstname: string = null,
    public lastname: string = null,
    public patronymic: string = null,
    public iin: string = null,
    public certificateNumber: number = null,
    public certificateSeries: string = null,
    public testLangKaz: string  = null,
    public testLangRus: string  = null,
    public foreignLangCertificateTypeId: number = null,
    public foreignLangCertificateType: number = null,
    public foreignLangCertificateScore: string = null,
    public foreignLangCertificateTypeRus: string = null,
    public foreignLangCertificateTypeKaz: string = null,
    public gpa: number = null
  ) {

  }
}
