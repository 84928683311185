import {Action} from '@ngrx/store';
import {OperatorsModel} from '../shared/model/operators.model';

export namespace OPERATOR_ACTION {
  export const ADD_OPERATOR = 'ADD_CAR';
  export const EDIT_OPERATOR = 'EDIT_OPERATOR';
  export const REMOVE_OPERATOR = 'REMOVE_OPERATOR';
  export const LOAD_OPERATOR = 'LOAD_OPERATOR';
}



export class AddOperator implements Action {
  readonly type = OPERATOR_ACTION.ADD_OPERATOR;

  constructor(public payload: OperatorsModel) { }
}

export class EditOperator implements Action {
  readonly  type = OPERATOR_ACTION.EDIT_OPERATOR;

  constructor(public payload: OperatorsModel) { }
}

export class RemoveOperator implements Action {
  readonly  type = OPERATOR_ACTION.REMOVE_OPERATOR;

  constructor(public payload: OperatorsModel) { }
}

export class LoadOperator implements Action {
  readonly  type = OPERATOR_ACTION.LOAD_OPERATOR;

  constructor(public payload: OperatorsModel[]) { }
}


export type OperatorsAction = AddOperator | EditOperator | RemoveOperator | LoadOperator;


