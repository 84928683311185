import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {ErrorModel} from '../../zayav/shared/model/error.model';
import {ErrorMessage} from '../../shared/model/error.model';

@Injectable()
export class AuthRepository {

  oblLists: any[];
  vuzLists: any[];
  usersLists: any[];


  constructor(
    public authService: AuthService,
    public router: Router
  ) {
    this.getObl();
  }



  getObl() {
    this.authService.getObl()
      .subscribe(data => {
        this.oblLists = data.data;
      })
  }


  getVuz(oblId: number) {
    this.usersLists = [];

    this.authService.getVuz(oblId)
      .subscribe(data => {
        this.vuzLists = data.data;
      })
  }


  getUsers(vpzId: number) {
    this.authService.getUsers(vpzId)
      .subscribe(data => {
        this.usersLists = data.data;
      })
  }





}
