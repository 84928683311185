import {Injectable} from '@angular/core';
import {ErrorModel} from '../../zayav/shared/model/error.model';

@Injectable()
export class CommonModel {
  message(error: ErrorModel, text, status = 'success') {
    error.errorText = text;
    error.errorStatus = status;

    setTimeout(() => {
      error.errorText = '';
    }, 15000);
  }

  update(data: any, item) {
    for (let key in item) {
      data[key] = item[key];
    };
  }

}
