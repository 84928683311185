import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'count'
})
export class CountPipe implements PipeTransform {
  transform(value: number, args?: any) {
    if (value.toString().length < args) {
      return new Array(args - value.toString().length).fill(0).join('') + value;
    }
    return value;
  }
}
