export class CloseModel {
  random1: number;
  random2: number;
  result: number;

  constructor() {
    this.random();
  }

  random() {
    this.random1 = Math.floor(Math.random() * (10 - 1) + 1);
    this.random2 = Math.floor(Math.random() * (10 - 1) + 1);
    this.result = this.random1 + this.random2;
  }

  answer() {
    return this.result;
  }
}


