export class CompetitionChoicesModel {
  constructor(
    public eduProgramId: number,
    public instituteId: number,
    public choiceOrder: number,
    public useQuotaGrants: number
  ) {

  }
}
