import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {ZayavModule} from './zayav/zayav.module';
import {AuthModule} from './auth/auth.module';
import {AppRoutingModule} from './app-routing.module';
import {AdminModule} from './admin/admin.module';
import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpModule} from '@angular/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {AuthGuard} from './shared/auth.guard';
import {AuthService} from './shared/auth.service';
import {ComponentModule} from './shared/components/component.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { PaginationModule,PaginationConfig } from 'ngx-bootstrap/pagination';



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ZayavModule,
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    AdminModule,
    HttpModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    ComponentModule,
    PaginationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [BrowserAnimationsModule, NoopAnimationsModule],
  providers: [AuthGuard, AuthService,PaginationConfig],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
