import { Component, OnInit } from '@angular/core';
import {AuthRepository} from './shared/auth.repository';



@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(
    public authRepository: AuthRepository

  ) { }

  ngOnInit() {

  }

}
