export class FindAllReggedModel {
  constructor(
    public ikt: number = null, /*ИКТ студента*/
    public iin: string = null, /*ИИН студента*/
    public certificateNumber: number = null, /*Номер сертификата студента*/
    public lastname: string = null, /*Фамилия студента*/
    public index: number = 0, /*индекс(пагинация)*/
    public limit: number = 20 /*лимит(пагинация)*/
  ) { }
}
