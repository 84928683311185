import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseApi } from 'src/app/shared/core/base-api';
import { Http } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class StatService  {

  constructor(private httpClient: HttpClient, private http:Http) {
  }


  public downloadZayav(): Observable<Blob> {
    const url = new BaseApi(this.http).getUrl('konkurs/zayav/pvo/api/institute/instituteStatisticsExcel');

    const headers = new HttpHeaders()
      .set('token', JSON.parse(sessionStorage.getItem('user')).token);

      
    return this.httpClient.get(url, { responseType: 'blob', 'headers': headers });
  }

  public downloadGrantCertificate(): Observable<Blob> {
    const url = new BaseApi(this.http).getUrl('konkurs/zayav/pvo/api/institute/grantCertificateExcel');

    const headers = new HttpHeaders()
      .set('token', JSON.parse(sessionStorage.getItem('user')).token);
    return this.httpClient.get(url, { responseType: 'blob', 'headers': headers });
  }


}
