export class AuthService {

  private isAuthenticated = false;

  constructor() {
    if (sessionStorage.getItem('user')) {
      this.login();
    }
  }

  login() {
    this.isAuthenticated = true;
  }

  logout() {
    this.isAuthenticated = false;
    sessionStorage.clear();
  }

  authenticated() {
    if (this.isAuthenticated) {
      return true;
    } else {
      return false;
    }
  }


  isLoggedIn(): boolean {
    return this.isAuthenticated;
  }

}
