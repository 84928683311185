import {NgModule} from '@angular/core';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {CommonModule} from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    TranslateModule
  ],
  declarations: [
    FooterComponent,
    HeaderComponent
  ],
  providers: [],
  exports: [
    FooterComponent,
    HeaderComponent,
    TranslateModule
  ]
})



export class ComponentModule {

}

