import {Component, OnInit, TemplateRef} from '@angular/core';
import {RepositoryModel} from '../shared/model/repository.model';
import {ZayavService} from '../shared/zayav.service';
import {IStudents} from '../shared/model/interfaces';
import {ErrorMessage} from '../../shared/model/error.model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-active-abit',
  templateUrl: './active-abit.component.html',
  styleUrls: ['./active-abit.component.css']
})

export class ActiveAbitComponent implements OnInit {
  abitLists: IStudents[];
  id: number;
  message: ErrorMessage = new ErrorMessage();
  modalRef: BsModalRef;
  search: string;

  constructor(
    public repositoryModel: RepositoryModel,
    public zayavService: ZayavService,
    private modalService: BsModalService,
    public translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.zayavService.findAllabiturients()
      .subscribe(data => {
        this.abitLists = data.datas;
      });
  }

  current(abit: any) {
    this.id = abit.id;
  }

  revoke(id: number) {
    this.zayavService.revokeAbit(id)
      .subscribe(data => {
        this.message.message(this.translate.currentLang === 'ru' ? 'Поступающий успешно удален' : 'Түсуші сәтті жойылды', 'success');
        this.abitLists = this.abitLists.filter(item => id !== item.id);
        this.modalRef.hide();
      }, error => {
        this.message.message(this.translate.currentLang === 'ru' ? error.errorMessageRu : error.errorMessageKz, 'danger');
        this.modalRef.hide();
      })
  }

  show(template: TemplateRef<any>, id: number ) {
    this.id = id;
    this.modalRef = this.modalService.show(template);
  }

  decline() {
    this.modalRef.hide();
  }

  delete() {
    this.revoke(this.id);
  }
}
