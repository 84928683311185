import {Component, OnInit, TemplateRef} from '@angular/core';
import {ZayavService} from '../shared/zayav.service';
import {Router} from '@angular/router';
import {ErrorMessage} from '../../shared/model/error.model';
import {RepositoryModel} from '../shared/model/repository.model';
import {FormControl, FormGroup} from '@angular/forms';
import {FindAllReggedModel} from '../shared/model/find-all-regged.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import {IStudents} from '../shared/model/interfaces';
import {Students} from '../shared/model/students';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-zayav-lists',
  templateUrl: './zayav-lists.component.html',
  styleUrls: ['./zayav-lists.component.css']
})
export class ZayavListsComponent implements OnInit {

  myForm: FormGroup;
  ikt: number = null;
  abit: Students;
  message: ErrorMessage = new ErrorMessage();
  lists: IStudents[] =  [];
  contentArray = new Array(90).fill('');
  returnedArray: string[];
  itemsPerPage: number = 20;
  totalCount: number;
  modalRef: BsModalRef;
  id: number;
  showReport: boolean = false;
  startItem: number = 0;
  random1: number;
  random2: number;
  result: number;
  answer: number;
  showQuestion: boolean = false;

  constructor(
    private router: Router,
    public repositoryModel: RepositoryModel,
    public dataSource: ZayavService,
    private modalService: BsModalService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.contentArray = this.contentArray.map((v: string, i: number) => `Content line ${i + 1}`);
    this.abit = new Students();
    this.findAllReggedAbiturients(new FindAllReggedModel());
    this.myForm = new FormGroup({
      ikt: new FormControl(null),
      iin: new FormControl(null),
      lastname: new FormControl(null),
      certificateNumber: new FormControl(null)
    });

    if (JSON.parse(sessionStorage.getItem('user')).rId == 2) {
      this.showReport = true;
    }
  }

  findAllReggedAbiturients(data: FindAllReggedModel) {
    this.dataSource.findAllReggedAbiturients(data)
      .subscribe(data => {
        this.lists = data.datas;
        this.totalCount = data.totalCount;
      })
  }


  search() {
    this.findAllReggedAbiturients(
      new FindAllReggedModel(
        this.myForm.value.ikt,
        this.myForm.value.iin,
        this.myForm.value.certificateNumber,
        this.myForm.value.lastname
      )
    );
    this.myForm.reset();
  }


  pageChanged(event: PageChangedEvent): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;

    this.returnedArray = this.contentArray.slice(this.startItem, endItem);
    this.findAllReggedAbiturients(new FindAllReggedModel(null, null, null, null, this.startItem, event.itemsPerPage));
  }

  abitInfo(abit) {
    this.abit = abit;
  }

  certificate(lang: number) {
    const token = JSON.parse(sessionStorage.getItem('user')).token;
    window.open(`./konkurs/zayav/pvo/api/forms/certificate?ikt=${this.abit.ikt}&langId=${lang}&token=${token}`)
  }

  report(format: string) {
    const lang = this.translate.currentLang === 'ru' ? 2 : 1;
    const token = JSON.parse(sessionStorage.getItem('user')).token;
    window.open(`./konkurs/zayav/pvo/api/forms/applicationJournal?langId=${lang}&format=${format}&token=${token}`)
  }

  reportOperator(format: string) {
    const lang = this.translate.currentLang === 'ru' ? 2 : 1;
    const token = JSON.parse(sessionStorage.getItem('user')).token;
    window.open(`./konkurs/zayav/pvo/api/forms/applicationJournalOperator?langId=${lang}&format=${format}&token=${token}`)
  }

  revokeAbit(id: number) {
    this.dataSource.revokeAbiturients(id)
      .subscribe(data => {
        const index = this.lists.findIndex(item => item.id === id);
        this.lists.splice(index, 1);
        this.message.message(this.translate.currentLang === 'ru' ? 'Поступающий успешно отозван' : 'Түсуші сәтті кері қайтарылды', 'success');
        this.modalRef.hide();
      }, error => {
        this.message.message(this.translate.currentLang === 'ru' ? error.errorMessageRu : error.errorMessageKz, 'danger');
        this.modalRef.hide();
      })
  }


  total(ikt: number) {
    const lang = this.translate.currentLang === 'ru' ? 2 : 1;
    const token = JSON.parse(sessionStorage.getItem('user')).token;
    window.open(`./konkurs/zayav/pvo/api/forms/totalAbiturientStatistics?langId=${lang}&format=pdf&token=${token}`)
  }

  onePerPage(itemsPerPage: string) {
    this.itemsPerPage = +itemsPerPage;
    this.findAllReggedAbiturients(new FindAllReggedModel(null, null, null, null, 0, +itemsPerPage));
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  show(template: TemplateRef<any>, abit?: any ) {
    if(abit) this.abit = abit;
    this.modalRef = this.modalService.show(template);
    this.answer = null;
    this.showQuestion = false;
    this.random();
  }


  decline() {
    this.modalRef.hide();
  }

  delete() {
    if (this.answer === this.result) {
      this.revokeAbit(this.abit.id);
    } else {
      this.modalRef.hide();
      this.message.message(this.translate.currentLang === 'ru' ? 'Неверный ответ' : 'Жауап дұрыс емес', 'danger');
    }
  }


  random() {
    this.random1 = Math.floor(Math.random() * (10 - 1) + 1);
    this.random2 = Math.floor(Math.random() * (10 - 1) + 1);
    this.result = this.random1 + this.random2;
  }



}
