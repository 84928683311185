import {BaseApi} from '../../../shared/core/base-api';
import {Http} from '@angular/http';
import {Injectable} from '@angular/core';
import {AppState} from '../../reducers/app.state';
import {Store} from '@ngrx/store';
import {AddOperator, EditOperator, LoadOperator, RemoveOperator} from '../../actions/operator';
import {OperatorsModel} from '../model/operators.model';

@Injectable()
export class OperatorsService extends BaseApi {

  constructor(
    public http: Http,
    private store: Store<AppState>
  ) {
    super(http);
    this.findAllOperators();
  }

  findAllOperators() {
    return this.get(`konkurs/zayav/pvo/api/operators/findAll`)
      .subscribe(data => this.store.dispatch(new LoadOperator(data.data)));
  }

  addOperators(data) {
    return this.post(`konkurs/zayav/pvo/api/operators/add`, data);
  }

  updateOperators(data) {
    return this.put(`konkurs/zayav/pvo/api/operators/update`, data);
  }

  removeOperators(id: number) {
    return this.delete(`konkurs/zayav/pvo/api/operators/delete?id=${id}`);
  }


}


