import { CompetitionChoicesModel } from './competition-choices.model';

export class AbiturientsModel {
  constructor(
    public ikt: number = null, /*ИКТ студента*/
    public iin: string = null,
    public hasPerfectDiploma: number = null, /*Диплом с отличием*/
    public isInvalid: number = null, /*Инвалид*/
    public isWarVeteran: number = null, /*Ветеран*/
    public isTipo: number = null, /*ТиПО*/
    public foreignLangCertificateTypeId: number = null, /*id сертификата иностранного языка*/
    public isOrphan: number = null, /*Сирота*/
    public olimpTypeId: number = null, /*id тип олимпиады*/
    public olimpSubjectId: number = null, /*id предмет олимпиады*/
    public competitionChoices: CompetitionChoicesModel[] = [],
    public studyFormId: number = null, /*id формы обучения*/
    public diploma3Count: number = null, /*Количество 3*/
    public diploma4Count: number = null, /*Количество 4*/
    public diploma5Count: number = null, /*Количество 5*/
    public creativeScore1: number = null,
    public creativeScore2: number = null,
    public gpa: number = null,
    public foreignLangCertificateType: number = null,
    public foreignLangCertificateScore: any = null,
    public foreignLangCertificateDate: any = null,
    public foreignLangCertificateRegNumber: any = null,
    public instituteEduPrograms: any[] = [],
    public greRegNumber: string = null,
    public grePrintDate: any = null,
    public quantitativeReasoningScore: number = null,
    public analyticalWritingScore: number = null,
    public verbalReasoningScore: number = null,
    public isGreExists: number = null,
    public greTestDate: number = null,
    public agr: boolean = false
  ) { }
}
