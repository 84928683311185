export class Students {
  constructor(
    public id: number = null,     /*id студента*/
    public ikt: number = null,    /*ИКТ студента*/
    public lastname: string = null, /*Фамилия студента*/
    public firstname: string = null, /*Имя студента*/
    public patronymic: string = null, /*Отчество студента*/
    public certificateSeries: string = null, /*Серия сертификата студента*/
    public certificateNumber: number = null, /*Номер сертификата студента*/
    public studyFormNameKaz: string = null, /*форма обучения каз*/
    public studyFormNameRus: string = null, /*форма обучения рус*/
    public statusId: number = null,  /*статус*/
    public eduProgram1Code: number = null, /*id образовательной программы*/
    public eduProgram2Code: number = null, /*код вуза*/
    public eduProgram3Code: number = null,
    public eduProgram4Code: number = null,
    public institute1Id: number = null,
    public institute2Id: number = null,
    public institute3Id: number = null,
    public institute4Id: number = null

  ) {}
}
