import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UnivercityEmployeeComponent} from './univercity-employee/univercity-employee.component';
import {AuthComponent} from './auth.component';
import {MainAuthComponent} from './main-auth/main-auth.component';




const routes: Routes = [
    { path: 'auth', component: AuthComponent, children: [
        { path: '', component: MainAuthComponent },
        { path: 'univercity', component: UnivercityEmployeeComponent }
     ]
    }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AuthRoutingModule {

}
