import {BaseApi} from '../../shared/core/base-api';
import {Http} from '@angular/http';
import {Injectable} from '@angular/core';


@Injectable()
export class AuthService extends  BaseApi{
  constructor(public http: Http) {
    super(http);
  }


  getObl() {
    return this.getWithoutToken(`konkurs/zayav/pvo/api/users/obl`);
  }


  getVuz(oblId: number) {
    return this.getWithoutToken(`konkurs/zayav/pvo/api/users/oblId/${oblId}/vuz`);
  }

  getUsers(vpzId: number) {
    return this.getWithoutToken(`konkurs/zayav/pvo/api/users/findAll/vpzId/${vpzId}`);
  }

  auth(data) {
    return this.postAuth(`konkurs/zayav/pvo/api/users/login`, data);
  }

}
