import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-main-auth',
  templateUrl: './main-auth.component.html',
  styleUrls: ['./main-auth.component.css']
})
export class MainAuthComponent implements OnInit {

  myForm: FormGroup;


  constructor() {
  }

  ngOnInit() {

  }


}
