import {OperatorsAction} from '../actions/operator';
import {OPERATOR_ACTION} from '../actions/operator';


const initialState = {
  operators: []
};

export function operatorReducer(state = initialState, action: OperatorsAction) {
  switch (action.type) {
    case OPERATOR_ACTION.ADD_OPERATOR:
      return {
        ...state,
        operators: [...state.operators, action.payload]
      };
    case OPERATOR_ACTION.EDIT_OPERATOR:
      const find = state.operators.find(o => o.id === action.payload.id);
      for (const key in find) find[key] = action.payload[key];
      return {
        ...state,
        operators: [...state.operators]
      };
    case OPERATOR_ACTION.REMOVE_OPERATOR:
      return {
        ...state,
        operators: [...state.operators.filter(o => o.id !== action.payload.id)]
      };
    case OPERATOR_ACTION.LOAD_OPERATOR:
      return {
        ...state,
        operators: [...action.payload]
      };
    default:
      return state;
  }
}
