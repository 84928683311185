import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {OperatorsModel} from '../../shared/model/operators.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OperatorsService} from '../../shared/services/operators.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ErrorMessage} from '../../../shared/model/error.model';
import {AddOperator} from '../../actions/operator';
import {AppState} from '../../reducers/app.state';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  modalRef: BsModalRef;
  form: FormGroup;
  activeOperatorId: number;
  submitted: boolean = false;
  message: ErrorMessage = new ErrorMessage();

  @Output()
  text: EventEmitter<string> = new EventEmitter<string>();


  @ViewChild("template") tpl: TemplateRef<any>;

  constructor(
    public store: Store<AppState>,
    public operatorsService: OperatorsService,
    private modalService: BsModalService,
    public translate: TranslateService
  ) {
  };

  ngOnInit() {
    this.form = this.formValidtation(null);
  }

  show(): void {
    this.openModal(this.tpl);
  }

  formValidtation(data: OperatorsModel): FormGroup {
    return new FormGroup({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      code: new FormControl(null, [Validators.required])
    });
  }

  add() {
     this.submitted = true;
     if (!this.form.invalid) {
       this.operatorsService.addOperators(this.form.value)
         .subscribe(data => {
           this.store.dispatch(new AddOperator(data.data));
           this.modalRef.hide();
           this.text.emit('Оператор сохранен успешно');
         }, error => {
           this.message.message(this.translate.currentLang === 'ru' ? error.errorMessageRu : error.errorMessageKz);
         });
     }

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalService.onHide.subscribe(data => {
      this.form.reset();
    })
  }

  get errors() {
    return this.form.controls;
  };

}
