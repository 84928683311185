import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthRepository} from '../shared/auth.repository';
import {AuthService} from '../shared/auth.service';
import {ErrorMessage} from '../../shared/model/error.model';
import {Auth} from '../../shared/model/auth.model';
import {AuthService as  AuthGuard} from '../../shared/auth.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-univercity-employee',
  templateUrl: './univercity-employee.component.html',
  styleUrls: ['./univercity-employee.component.css']
})
export class UnivercityEmployeeComponent implements OnInit {

  myForm: FormGroup;
  submitted: boolean = false;
  error = new ErrorMessage();

  constructor(
    public authRepository: AuthRepository,
    private authService: AuthService,
    public router: Router,
    public authGuard: AuthGuard,
    public translate: TranslateService

  ) { }

  ngOnInit() {
    this.myForm = new FormGroup({
      'oblId': new FormControl(null, [Validators.required]),
      'userId': new FormControl(0, [Validators.required]),
      'password': new FormControl(null, [Validators.required]),
      'vpzId': new FormControl(0, [Validators.required])
    });
  }

  auth() {
    this.submitted = true;
    if (!this.myForm.invalid) {
      this.authService.auth(new Auth(this.myForm.value.userId, this.myForm.value.password, this.myForm.value.vpzId))
        .subscribe(data => {
          this.router.navigateByUrl('/zayav')
          sessionStorage.setItem('user', JSON.stringify(data));
          this.authGuard.login();
          this.submitted = false;
        }, error => {
          this.error.message(this.translate.currentLang === 'ru' ? error.errorMessageRu : error.errorMessageKz);
        })
    }
  }

  getVuz(oblId: number) {
    this.myForm.get('vpzId').setValue(0);
    this.myForm.get('userId').setValue(0);
    this.authRepository.getVuz(+oblId);
  }

  getUsers(vuzId: number) {
    this.myForm.get('userId').setValue(0);
    this.authRepository.getUsers(vuzId);
  }

  get errors() {
    return this.myForm.controls;
  }
}


