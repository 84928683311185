import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ZayavComponent} from './zayav.component';
import {SearchAbitComponent} from './search-abit/search-abit.component';
import {AddAbitComponent} from './add-abit/add-abit.component';
import {ActiveAbitComponent} from './active-abit/active-abit.component';
import {ZayavListsComponent} from './zayav-lists/zayav-lists.component';
import {AdminComponent} from '../admin/admin.component';
import {OperatorsComponent} from '../admin/operators/operators.component';
import {AuthGuard} from '../shared/auth.guard';
import {RevokedListsComponent} from './revoked-lists/revoked-lists.component';
import {StatComponent} from './stat/stat.component';
import { GrantListsComponent } from './grant-lists/grant-lists.component';




const routes: Routes = [
   { path: 'zayav', canActivate: [AuthGuard], component: ZayavComponent, children: [
       { path: 'zayav-lists', component: ZayavListsComponent},
       { path: 'search', component: SearchAbitComponent},
       { path: 'add-abit/:ikt', component: AddAbitComponent},
       { path: 'grant-lists', component: GrantListsComponent},
       { path: 'active', component: ActiveAbitComponent},
       { path: 'admin', component: OperatorsComponent },
       { path: 'stat', component: StatComponent },
       { path: 'revoked-lists', component: RevokedListsComponent }
    ]},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ZayavRoutingModule {

}

