import {Component, OnInit, TemplateRef} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ZayavService} from '../shared/zayav.service';
import {CloseModel} from '../../shared/model/close.model';
import {ErrorMessage} from '../../shared/model/error.model';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  current: number;
  show: boolean = false;
  showDB: boolean = false;
  modalRef: BsModalRef;
  showDataBase: number;
  close: CloseModel = new CloseModel();
  message: ErrorMessage = new ErrorMessage();
  result: number;
  showQuestion: boolean = false;

  constructor(
    public router: Router,
    private modalService: BsModalService,
    private dataSoure: ZayavService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.showDataBase = JSON.parse(sessionStorage.getItem('user')).vpzStatusId;
    if (JSON.parse(sessionStorage.getItem('user')).rId === 2) {
      this.show = true;
    }
    this.isClosedDb();
  }

  isClosedDb(){
    this.dataSoure.isClosedDB()
      .subscribe((data: any) =>{
        this.showDB = data.status;
      }, error =>{
        this.message.message(this.translate.currentLang === 'ru' ? error.errorMessageRu : error.errorMessageKz);
        this.showDB = error.status;
      });
  }

  active(index: number) {
    this.current = index;
  }

  link() {
    this.router.navigateByUrl('zayav/admin');
  }

  showModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.close.random();
  }

  decline() {
    this.modalRef.hide();
  }

  closeDataBase() {
    if (this.close.answer() === this.result) {
      this.dataSoure.closeDataBase()
        .subscribe(data => {
          this.showDB = false;
          this.showDataBase = 0;
          this.decline();
        }, error => {
          this.message.message(this.translate.currentLang === 'ru' ? error.errorMessageRu : error.errorMessageKz);
        })
    } else {
      this.message.message(this.translate.currentLang === 'ru' ? 'неверный ответ' : 'жауап жұрыс емес');
    }

  }

}
