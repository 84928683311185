import {NgModule} from '@angular/core';
import {FilterPipe} from './filter.pipe';
import {CountPipe} from '../../zayav/shared/pipe/count.pipe';
import {PaginationPipe} from './pagination.pipe';

@NgModule({
  declarations: [ FilterPipe, CountPipe, PaginationPipe],
  exports: [ FilterPipe, CountPipe, PaginationPipe ]
})
export class PipesModule {

}
