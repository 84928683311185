import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthComponent} from './auth.component';
import { UnivercityEmployeeComponent } from './univercity-employee/univercity-employee.component';
import {AuthRoutingModule} from './auth-routing.module';
import { MainAuthComponent } from './main-auth/main-auth.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthRepository} from './shared/auth.repository';
import {AuthService} from './shared/auth.service';
import {PasswordModule} from 'primeng/password';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordModule,
    TranslateModule
  ],
  declarations: [
    AuthComponent,
    UnivercityEmployeeComponent,
    MainAuthComponent
  ],
  providers: [AuthRepository, AuthService],
  exports: [AuthComponent]
})



export class AuthModule {

}

