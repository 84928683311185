import {Component, OnInit} from '@angular/core';



@Component({
  selector: 'app-zayav',
  templateUrl: './zayav.component.html',
  styleUrls: ['zayav.component.css']
})

export class ZayavComponent implements  OnInit {

  ngOnInit() {

  }





}



