import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ZayavService } from '../shared/zayav.service';
import { TranslateService } from '@ngx-translate/core';
import { StatService } from '../stat/stat.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-grant-lists',
  templateUrl: './grant-lists.component.html',
  styleUrls: ['./grant-lists.component.css']
})
export class GrantListsComponent implements OnInit {

  data: any[] = [];
  downloadButton = false;
  search: string;

  constructor(private service: ZayavService, public translate: TranslateService, private statService: StatService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.service.grantCertificate().subscribe({
      next: (res) => {
        this.data = res;
      }
    });
  }

  searchIkt() {
    if (this.data.length > 0) {
      this.data = this.data.filter((item: any) => {
        if (item.studentTestId === this.search) {
          return item
        }
      });
      if(this.data.length === 0){
        this.getList();
      }
    } else {
      this.getList();
    }
  }


  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.data = this.data.slice(startItem, endItem);
    this.cd.detectChanges();
  }

  exportexcel(): void {
    this.downloadButton = true;

    this.statService.downloadGrantCertificate().subscribe({
      next: (res) => {
        this.downloadButton = false;
        const date = new Date();

        let currentDay = String(date.getDate()).padStart(2, '0');

        let currentMonth = String(date.getMonth() + 1).padStart(2, "0");

        let currentYear = date.getFullYear();

        let currentDate = `${currentDay}-${currentMonth}-${currentYear}`;
        saveAs(res, "Список поступивших на грант_ " + currentDate + ".xlsx");
      }
    });

  }
}
