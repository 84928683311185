import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ZayavService} from '../shared/zayav.service';
import {RepositoryModel} from '../shared/model/repository.model';
import {ErrorMessage} from '../../shared/model/error.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-search-abit',
  templateUrl: './search-abit.component.html',
  styleUrls: ['./search-abit.component.css']
})
export class SearchAbitComponent implements OnInit {

  message: ErrorMessage = new ErrorMessage();
  myForm: FormGroup;
  itemsPerPage: number = 15;
  currentPage: number = 1;

  constructor(
    private zayavService: ZayavService,
    public repositoryModel: RepositoryModel,
    public translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.myForm = new FormGroup({
      ikt: new FormControl(null),
      iin: new FormControl(null),
      lastname: new FormControl(null),
      certificateNumber: new FormControl(null)
    });
  }

  searchAbit() {
    this.repositoryModel.searchAbit(this.myForm, this.message);

  }

  studentData(ikt: number) {
    this.repositoryModel.findStudent(ikt);
  }

  count(ikt: number) {
    if (ikt.toString().length < 9) {
      return new Array(9 - ikt.toString().length).fill(0).join('') + ikt;
    }
    return ikt;
  }

}
